<template>
  <v-app>
    <v-app-bar
      app
    >
      <!-- Changer logo -->
      <div class="d-flex align-center">
        <v-btn 
          id="no-background-hover"
          :to="'/'"
          :ripple="false"
          text
          depressed
          > 
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="./assets/logo_comite.png"
            transition="scale-transition"
            width="100"
          />
        </v-btn>
      </div>

      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          :to="'nous-connaitre'"
          text
        >
          Nous connaître
        </v-btn>

        <v-btn
          :to="'s-informer'"
          text
        >
          S'informer
        </v-btn>

        <v-btn
          :to="'livret-inclusivite'"
          text
        >
          Livret Inclusivité
        </v-btn>

        <v-btn
          :to="'contact'"
          text
        >
          Nous contacter
        </v-btn>

      </v-toolbar-items>





    </v-app-bar>

    <v-main>
      <HelloWorld/>
    <v-slide-x-transition>
      <router-view>
      </router-view>
    </v-slide-x-transition>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
#no-background-hover::before {
   background-color: transparent !important;
}
</style>