import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () =>
        import('./views/Home.vue'),
    },
    {
      path: '/nous-connaitre',
      name: 'nous-connaitre',
      component: () =>
        import('./views/NousConnaitre.vue'),
    },
    {
      path: '/s-informer',
      name: 's-informer',
      component: () =>
        import('./views/SInformer.vue'),
    },
    {
      path: '/livret-inclusivite',
      name: 'livret-inclusivite',
      component: () =>
        import('./views/LivretInclusivite.vue'),
    },
    {
      path: '/contact',
      name: 'contact',
      component: () =>
        import('./views/Contact.vue'),
    },
  ],
})

export default router
